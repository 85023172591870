@media (max-width: 767px) {
    input[type=submit] {
        -webkit-appearance: button;
        cursor: pointer;
        line-height: 0;
        height: 39px;
    }

    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form{
        height:auto !important;
        border-bottom-color: #AB864F !important;
        color: #004b91;
        font-size: 1.3px;
        font-size: 1.3rem;
        background-color: #EFF2D7;
        background-color: rgba(239,242,215,0.6);
        background-position: center bottom;
        padding: 0px;
        background-image: none;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a{
        color: #004b91;
    }

    .navbar-header{
        background-color: transparent;
        border-top: none;
        border-bottom: none;
    }

    .navbar-default .navbar-toggle{
        border: none;
    }

    .navbar-default .navbar-toggle .icon-bar{
        background-color: #A67B51;
    }
    .navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover{
        background-color: transparent;
    }
    .nav>li.divider{
        display: none;
    }

    .navbar-default .navbar-nav>li>a, .navbar-default .navbar-text{
        text-align: left;
        border-bottom: solid 1px #AB864F;
    }

    .navbar-nav{
        margin: 0;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #f2f2f2;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header, .navbar-nav .open .dropdown-menu>li>a {
        padding: 10px;
        border-top: solid 1px #eee;
        border-bottom: solid 1px #ddd;
    }


    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover{
        background-color: #EFF2D7; display: block;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a{
        background-color: #ffffff;
        border-bottom: #AB864F 1px solid;
    }

    .dropdown:hover .dropdown-menu {
        display: inline;
        z-index: -999999;
        height: 0px;
        overflow: hidden;
    }

    .nav{
        overflow: hidden;
    }

    #footer ul {
        padding-left: 30px;
    }

    .feedback-footer{
        float: left;
        padding-bottom: 10px;
    }

    .mobile-inline{
        display: inline-block;
        float: right;
        padding-bottom: 15px;
    }
    .advanced-search-toggle{
        margin-top: 45px;
        font-size: 16px;
    }

    .gradientReverse{
        height: auto;
    }

    .header-text-cta{
        display: inline-block;
    }

    .logo-mobile{
        padding-bottom: 10px;
        padding-top: 10px;
        width: 70px;
    }
    .m-10-percent{
        margin-top: 14%;
    }

    .navbar-default{
        background-color: transparent;
        border: none;
        padding-bottom: 5px;
    }
    .navbar-default{
        background-color: transparent;
        border: none;
        min-height: 0px;
    }

    .navbar-toggle{
        margin: 0;
        margin-top: 21px;
    }

    #search-div-mobi{
        display: none;
        position: absolute;
        z-index: 100;
        height: 43px;
        background-color: #EFF2D7;
        width: 100%;
        padding: 9px;
        left: 0px;
        border-bottom: solid #A67B51 1px;
    }

    .mobi-brown{
        background: #ede4ce; /* Old browsers */
        background: -moz-linear-gradient(top,  #ede4ce 0%, #ffffff 11%, #ffffff 94%, #ede4ce 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ede4ce), color-stop(11%,#ffffff), color-stop(94%,#ffffff), color-stop(100%,#ede4ce)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* IE10+ */
        background: linear-gradient(to bottom,  #ede4ce 0%,#ffffff 11%,#ffffff 94%,#ede4ce 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ede4ce', endColorstr='#ede4ce',GradientType=0 ); /* IE6-9 */
    }


    .mobi-search{
        position: absolute;
        right: 56px;
        top: 26px;
        z-index: 100;
        width: 19px;
    }

    #textbox1{
        width: 89% !important;
        height: 28px;
        margin-top: -2px;
    }

    .navbar-default .navbar-collapse,
    .navbar-default .navbar-form{
        border-top: none;
    }

}
